import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/ChallengesPage.css';
import ReactGA from 'react-ga4';

const ChallengesPage = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Challenges Page',
      label: 'Challenges Page',
    });
  }, []);
  return (
    <div className="challenges-container">
      <div className="challenges-content">
        <h1>Software Architect's Challenges</h1>
        <p><b style={{ fontStyle: 'italic' }}>Software Architect's Challenges</b> is a unique series designed for professionals eager to tackle real-world architectural problems.
        Each challenge tackles real-world scenarios like scaling systems, optimizing cloud deployments, and building resilient microservices—helping you master critical design trade-offs and elevate your expertise.
        </p>
        <p>Are you ready to push your problem-solving skills and architect your way to success? Explore the challenges below!</p>
        <ul className="challenges-list">
          <li><Link to="/challenges/challenge6">Challenge 6: Designing a Multi-Tenant SaaS Architecture</Link></li>
          <li><Link to="/challenges/challenge5">Challenge 5: Designing a Scalable Database Architecture</Link></li>  
          <li><Link to="/challenges/challenge4">Challenge 4: Implementing API Gateway for Microservices</Link></li>  
          <li><Link to="/challenges/challenge3">Challenge 3: Designing an Event-Driven Architecture</Link></li>  
          <li><Link to="/challenges/challenge2">Challenge 2: Implementing Circuit Breaker in Microservices</Link></li>
          <li><Link to="/challenges/challenge1">Challenge 1: Implementing Authentication in Microservices</Link></li>
          {/* Add more challenges as needed */}
        </ul>
      </div>
    </div>
  );
};

export default ChallengesPage;
