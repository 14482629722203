import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/ChallengesPage.css';

const Challenge5 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Challenge 5 Page',
      label: 'Challenge 5 Page',
    });
  }, []);

  return (
    <div className="challenges-container">
      <div className="challenges-content">
        <h1>Challenge 5: Designing a Scalable Database Architecture</h1>

        {/* Overview */}
        <section className="principle-section">
          <h2>Overview</h2>
          <p>
            As applications grow, databases can become a bottleneck if not designed for scalability. A well-architected database must handle high read and write loads, ensure data consistency, and optimize performance while maintaining reliability.
          </p>
        </section>

        {/* Objective */}
        <section className="principle-section">
          <h2>Objective</h2>
          <p>
            Design a scalable database architecture for a high-traffic application, incorporating techniques such as sharding, replication, and caching to improve performance and fault tolerance.
          </p>
        </section>

        {/* Requirements */}
        <section className="principle-section">
          <h2>Requirements</h2>
          <ul className="big-bullet">
            <li>
              <strong>Database Setup:</strong>
              <ul className="small-bullet">
                <li>Choose between SQL (PostgreSQL, MySQL) or NoSQL (MongoDB, Cassandra) based on use case.</li>
                <li>Implement a schema that supports scalability.</li>
              </ul>
            </li>
            <li>
              <strong>Scalability Techniques:</strong>
              <ul className="small-bullet">
                <li><strong>Read Scaling:</strong> Use replication to distribute read queries.</li>
                <li><strong>Write Scaling:</strong> Implement database sharding or partitioning.</li>
                <li><strong>Performance Optimization:</strong> Use indexing, query optimization, and caching (Redis, Memcached).</li>
              </ul>
            </li>
            <li>
              <strong>Failover & High Availability:</strong>
              <ul className="small-bullet">
                <li>Ensure redundancy and failover strategies to prevent downtime.</li>
                <li>Use database clustering or cloud-managed solutions.</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* Steps to Solve */}
        <section className="principle-section">
          <h2>Steps to Solve</h2>
          <ul>
            <li>Define the application use case (e.g., e-commerce, social media, analytics).</li>
            <li>Choose the right database type and design an optimized schema.</li>
            <li>Implement replication for high-read scalability.</li>
            <li>Apply sharding techniques for distributed writes.</li>
            <li>Integrate caching mechanisms to reduce database load.</li>
            <li>Set up monitoring and failure recovery mechanisms.</li>
          </ul>
        </section>

        {/* Hints */}
        <section className="principle-section">
          <h2>Hints</h2>
          <ul>
            <li>Use database read replicas to distribute read queries.</li>
            <li>Implement consistent hashing for sharding strategies.</li>
            <li>Explore CQRS (Command Query Responsibility Segregation) for read/write separation.</li>
          </ul>
        </section>

        {/* Example Use Case */}
        <section className="principle-section">
          <h2>Example Use Case</h2>
          <p>
            Imagine an <strong>e-commerce application</strong> with millions of users:
            <ul>
              <li><strong>User Service:</strong> Stores user data, with read replicas to handle profile views.</li>
              <li><strong>Order Service:</strong> Uses sharded databases to distribute high write loads.</li>
              <li><strong>Product Service:</strong> Implements caching to speed up catalog searches.</li>
            </ul>
            <strong>Flow:</strong>
            <ul>
              <li>User places an order via <span className="highlighted-text">/order</span>.</li>
              <li>Order Service writes order data to a sharded database.</li>
              <li>Read replicas handle frequent data fetches efficiently.</li>
            </ul>
          </p>
        </section>

        {/* Bonus Points */}
        <section className="principle-section">
          <h2>Bonus Points</h2>
          <ul>
            <li>Implement multi-region database replication for global scale.</li>
            <li>Design a disaster recovery strategy with automated backups.</li>
            <li>Use a polyglot persistence approach by combining SQL and NoSQL databases.</li>
          </ul>
        </section>

        {/* Resources */}
        <section className="principle-section">
          <h2>Resources</h2>
          <ul>
            <li><a href="https://www.postgresql.org/docs/current/high-availability.html" target="_blank" rel="noopener noreferrer">PostgreSQL High Availability Guide</a></li>
            <li><a href="https://cassandra.apache.org/doc/latest/" target="_blank" rel="noopener noreferrer">Apache Cassandra Documentation</a></li>
            <li><a href="https://redis.io/docs/manual/scaling/" target="_blank" rel="noopener noreferrer">Redis Scaling Documentation</a></li>
            <li>Other resources relevant to your tech stack.</li>
          </ul>
        </section>

        {/* Navigation Links */}
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/challenges">Back To Challenges</a>
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)', marginLeft: '30px' }} href="/challenges/challenge6">Next Challenge</a>
        
      </div>
    </div>
  );
};

export default Challenge5;
