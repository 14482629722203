import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson5 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lesson 5 Page',
      label: 'Lesson 5 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 5: API Gateway and Service Mesh Essentials</h1>
        <p>
          API Gateways and Service Meshes are essential components for building modern, resilient microservices architectures. 
          They enable effective routing, security, observability, and communication across distributed services.
        </p>

        {/* What Is an API Gateway? */}
        <section className="principle-section">
          <h2>1. What Is an API Gateway?</h2>
          <p>
            <strong>Explanation:</strong> An API Gateway acts as the single entry point for all client requests, managing routing, 
            authentication, and load balancing across multiple services.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Routing:</strong> Directs requests to the appropriate service based on URL, headers, or other parameters.
            </li>
            <li>
              <strong>Security:</strong> Handles authentication, authorization, and API rate limiting.
            </li>
            <li>
              <strong>Transformation:</strong> Converts request/response formats or protocols (e.g., REST to gRPC).
            </li>
          </ul>

          <p>
            <strong>Example Use Case:</strong> In an online shopping application, the API Gateway routes client requests: 
            <span className="highlighted-text">/cart/addItem</span> to the Cart Service, 
            <span className="highlighted-text">/products/{'{productId}'}</span> to the Product Service, 
            and <span className="highlighted-text">/users/{'{userId}'}</span> to the User Service, ensuring secure and seamless interactions.
          </p>
        </section>

        {/* What Is a Service Mesh? */}
        <section className="principle-section">
          <h2>2. What Is a Service Mesh?</h2>
          <p>
            <strong>Explanation:</strong> A Service Mesh is a dedicated infrastructure layer that handles communication between 
            microservices, providing features like observability, security, and traffic management.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Service Discovery:</strong> Helps services locate and communicate with each other dynamically.
            </li>
            <li>
              <strong>Traffic Control:</strong> Manages routing, load balancing, retries, and failovers for service-to-service communication.
            </li>
            <li>
              <strong>Observability:</strong> Provides metrics, logging, and tracing for monitoring service interactions.
            </li>
          </ul>

          <p>
            <strong>Example Use Case:</strong> In a payment processing system, a Service Mesh ensures secure communication 
            between the payment gateway service and fraud detection service while enabling detailed logging for compliance.
          </p>
        </section>

        {/* API Gateway vs. Service Mesh */}
        <section className="principle-section">
          <h2>3. API Gateway vs. Service Mesh</h2>
          <p>
            <strong>Explanation:</strong> While both tools address communication challenges in distributed systems, they serve different purposes.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>API Gateway:</strong> Focuses on client-to-service communication and is ideal for managing external traffic.
            </li>
            <li>
              <strong>Service Mesh:</strong> Handles service-to-service communication and is designed for internal service interactions.
            </li>
          </ul>

          <p>
            <strong>Example Use Case:</strong> A travel booking application uses an API Gateway to handle user-facing requests 
            like <span className="highlighted-text">/bookings/{'{bookingId}'}</span> while a Service Mesh ensures efficient internal communication between the 
            flight, hotel, and payment microservices.
          </p>
        </section>

        {/* Benefits of Using Both */}
        <section className="principle-section">
          <h2>4. Benefits of Using Both</h2>
          <p>
            <strong>Explanation:</strong> Combining an API Gateway with a Service Mesh provides comprehensive control over both external and internal traffic.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Improved Security:</strong> Centralized control over authentication and encryption for all communications.
            </li>
            <li>
              <strong>Enhanced Observability:</strong> Unified monitoring for external and internal traffic.
            </li>
            <li>
              <strong>Scalability:</strong> Efficient traffic management for both client and service communications.
            </li>
          </ul>

          <p>
            <strong>Example Use Case:</strong> In a logistics platform, the API Gateway ensures secure user access to 
            shipment tracking, while the Service Mesh monitors and optimizes interactions between warehouse and delivery services.
          </p>
        </section>

        {/* Challenges and Solutions */}
        <section className="principle-section">
          <h2>5. Challenges and Solutions</h2>
          <p>
            <strong>Explanation:</strong> Despite their benefits, API Gateways and Service Meshes come with challenges.
          </p>

          <ul className="big-bullet">
            <li>
              <strong>Challenge:</strong> Increased Complexity.
              <br />
              <strong>Solution:</strong> Adopt managed services or tools with robust documentation and community support.
            </li>
            <li>
              <strong>Challenge:</strong> Performance Overheads.
              <br />
              <strong>Solution:</strong> Optimize configurations and monitor resource usage closely.
            </li>
          </ul>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>
            API Gateways and Service Meshes are foundational tools for modern microservices architectures. By understanding their 
            unique roles and benefits, you can build scalable, secure, and resilient systems tailored to your business needs.
          </p>
        </section>

        {/* Redirections */}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }}
          href="/lessons"
        >
          Back To Lessons
        </a>
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            color: 'rgb(0, 84, 147)',
            marginLeft: '30px',
          }}
          href="/lessons/lesson6"
        >
          Next Lesson
        </a>
      </div>
    </div>
  );
};

export default Lesson5;
