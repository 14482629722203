import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson9 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lesson 9 Page',
      label: 'Lesson 9 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 9: Multi-Tenant Architecture – Designing Scalable and Secure SaaS Applications</h1>
        <p>
          Multi-tenant architecture is a key design pattern for SaaS applications, allowing multiple customers (tenants) to share the same infrastructure while maintaining data isolation and security.
        </p>

        {/* Single-Tenant vs. Multi-Tenant Models */}
        <section className="principle-section">
          <h2>1. Single-Tenant vs. Multi-Tenant Models</h2>
          <p>
            <strong>Explanation:</strong> Choosing between single-tenant and multi-tenant models impacts scalability, maintenance, and cost efficiency.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Single-Tenant:</strong> Each tenant has a dedicated instance of the application and database.
            </li>
            <li>
              <strong>Multi-Tenant:</strong> Multiple tenants share a common infrastructure with logical isolation.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A CRM platform offering single-tenant instances for enterprises with strict compliance needs, while SMBs use a shared multi-tenant instance for cost efficiency.
          </p>
        </section>

        {/* Data Isolation Strategies */}
        <section className="principle-section">
          <h2>2. Data Isolation Strategies</h2>
          <p>
            <strong>Explanation:</strong> Ensuring tenant data separation is critical for security and compliance.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Schema-Based Isolation:</strong> Each tenant has a separate database schema within the same instance.
            </li>
            <li>
              <strong>Database-Based Isolation:</strong> Each tenant has a dedicated database.
            </li>
            <li>
              <strong>Row-Level Security:</strong> Access control policies enforce tenant-specific data access.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A fintech SaaS platform uses database-based isolation for regulatory compliance, ensuring customer transactions remain private.
          </p>
        </section>

        {/* Scalability & Performance Considerations */}
        <section className="principle-section">
          <h2>3. Scalability & Performance Considerations</h2>
          <p>
            <strong>Explanation:</strong> Optimizing multi-tenant applications for scalability requires dynamic resource allocation.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Dynamic Scaling:</strong> Auto-scaling based on tenant load to ensure smooth performance.
            </li>
            <li>
              <strong>Resource Quotas:</strong> Preventing a single tenant from monopolizing resources.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A cloud-based analytics service dynamically allocates compute resources based on tenant usage patterns.
          </p>
        </section>

        {/* Security & Compliance */}
        <section className="principle-section">
          <h2>4. Security & Compliance</h2>
          <p>
            <strong>Explanation:</strong> Implementing strong security measures is crucial for protecting tenant data.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Identity & Access Management (IAM):</strong> Enforcing role-based access controls.
            </li>
            <li>
              <strong>Encryption:</strong> Encrypting data at rest and in transit.
            </li>
            <li>
              <strong>Audit Logging:</strong> Tracking access and changes to ensure compliance with regulations like GDPR.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A healthcare SaaS platform enforces strict IAM policies and audit logs to comply with HIPAA regulations.
          </p>
        </section>

        {/* Real-World Use Cases */}
        <section className="principle-section">
          <h2>5. Real-World Use Cases</h2>
          <p>
            <strong>Explanation:</strong> Many leading SaaS providers leverage multi-tenant architectures to optimize efficiency and cost.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Salesforce:</strong> Uses multi-tenant architecture to provide CRM services to businesses of all sizes.
            </li>
            <li>
              <strong>AWS RDS Multi-Tenant Databases:</strong> Allows cloud applications to share managed database resources securely.
            </li>
            <li>
              <strong>Shopify:</strong> Supports thousands of e-commerce stores with a shared multi-tenant architecture.
            </li>
          </ul>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>
            Multi-tenant architecture is a powerful approach for SaaS applications, enabling scalability, cost efficiency, and secure data isolation. Choosing the right model, security strategy, and scalability mechanisms ensures a robust and future-ready SaaS platform.
          </p>
        </section>

        {/* Redirections */}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }}
          href="/lessons"
        >
          Back To Lessons
        </a>
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            color: 'rgb(0, 84, 147)',
            marginLeft: '30px',
          }}
          href="/lessons/lesson10"
        >
          Next Lesson
        </a>
      </div>
    </div>
  );
};

export default Lesson9;
