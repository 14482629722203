import React, { useEffect } from 'react';
import '../styles/Introduction.css'; 
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Introduction = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Introduction Section',
      label: 'Introduction Section',
    });
  }, []);

  return (
    <div>
      {/* Intro Section - Image & Content */}
      <div className="intro-section">
        <div className="intro-image">
          <img src="/images/bhuvnesh-arya.avif" alt="Bhuvnesh Arya" />
        </div>
        <div className="intro-content">
          <h1>Insights That Transform Your Software Journey!</h1>
          <p className="intro-quote">
            “Insight Veda is your go-to platform for learning software architecture and engineering. Through expert lessons, real-world challenges, and curated resources, it empowers developers, technical leads, and architects with the skills and knowledge to design scalable systems, tackle complex problems, and drive innovation.”
          </p>
          <p>
            <Link style={{ color: '#0069d2' }} to="/bhuvnesh-arya">
              <b>Bhuvnesh Arya</b>
            </Link>, Software Architect
          </p>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
