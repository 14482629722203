import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson6 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lesson 6 Page',
      label: 'Lesson 6 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 6: Resilient Architecture Patterns for Microservices</h1>
        <p>
          Building resilient microservices is crucial for ensuring high availability, fault tolerance, and seamless user experience. 
          This lesson covers key patterns that help microservices handle failures gracefully.
        </p>

        {/* Circuit Breaker Pattern */}
        <section className="principle-section">
          <h2>1. Circuit Breaker Pattern</h2>
          <p>
            <strong>Explanation:</strong> The Circuit Breaker pattern prevents repeated failures by temporarily blocking requests 
            to a failing service, allowing it time to recover.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Closed State:</strong> Requests flow normally.
            </li>
            <li>
              <strong>Open State:</strong> Requests are blocked after failures exceed a threshold.
            </li>
            <li>
              <strong>Half-Open State:</strong> Limited requests are allowed to test recovery.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> In a payment processing system, if the external payment gateway 
            is down, the Circuit Breaker prevents overwhelming it with retries and instead returns a fallback response.
          </p>
        </section>

        {/* Retry & Backoff Strategies */}
        <section className="principle-section">
          <h2>2. Retry & Backoff Strategies</h2>
          <p>
            <strong>Explanation:</strong> Implementing retries with exponential backoff helps handle transient failures by spacing out retry attempts.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Exponential Backoff:</strong> Increases the delay between retries exponentially to avoid overwhelming services.
            </li>
            <li>
              <strong>Jitter:</strong> Introduces randomness to prevent synchronized retries from multiple clients.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A notification service retrying email delivery with increasing delays when the email provider experiences temporary downtime.
          </p>
        </section>

        {/* Bulkhead Pattern */}
        <section className="principle-section">
          <h2>3. Bulkhead Pattern</h2>
          <p>
            <strong>Explanation:</strong> The Bulkhead pattern isolates failures by creating separate resource pools, preventing a single failure from 
            bringing down the entire system.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Thread Pools:</strong> Allocating different thread pools for different service functionalities.
            </li>
            <li>
              <strong>Rate Limiting:</strong> Restricting resource usage to protect critical services.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A ride-hailing app ensures the booking service remains functional even if the map service experiences high traffic.
          </p>
        </section>

        {/* Failover & Load Balancing */}
        <section className="principle-section">
          <h2>4. Failover & Load Balancing</h2>
          <p>
            <strong>Explanation:</strong> These mechanisms ensure high availability by redirecting traffic to healthy instances or backup systems.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Failover:</strong> Automatically switching to a standby instance if the primary one fails.
            </li>
            <li>
              <strong>Load Balancing:</strong> Distributing traffic across multiple instances to prevent overload.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A cloud-based database automatically failing over to a replica in another region during an outage.
          </p>
        </section>

        {/* Observability & Monitoring */}
        <section className="principle-section">
          <h2>5. Observability & Monitoring</h2>
          <p>
            <strong>Explanation:</strong> Monitoring tools help detect failures before they escalate by providing real-time insights into system health.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Logging:</strong> Capturing critical events for debugging.
            </li>
            <li>
              <strong>Tracing:</strong> Tracking request flow across microservices.
            </li>
            <li>
              <strong>Metrics:</strong> Monitoring performance indicators like response times and error rates.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> An e-commerce platform uses Prometheus and Grafana to visualize API latency and detect issues in real time.
          </p>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>
            Implementing resilience patterns in microservices ensures system stability, even under failures and high loads. 
            By combining Circuit Breakers, Bulkheads, Retry Strategies, Failover Mechanisms, and Observability, you can build robust and reliable applications.
          </p>
        </section>

        {/* Redirections */}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }}
          href="/lessons"
        >
          Back To Lessons
        </a>
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            color: 'rgb(0, 84, 147)',
            marginLeft: '30px',
          }}
          href="/lessons/lesson7"
        >
          Next Lesson
        </a>
      </div>
    </div>
  );
};

export default Lesson6;
