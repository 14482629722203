import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson10 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lesson 10 Page',
      label: 'Lesson 10 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 10: Data Management Strategies for Modern Applications</h1>
        <p>
          Efficient data management is crucial for modern applications to ensure scalability, reliability, and performance.
          This lesson explores various data management strategies and best practices.
        </p>

        {/* Database Scaling */}
        <section className="principle-section">
          <h2>1. Database Scaling</h2>
          <p>
            <strong>Explanation:</strong> Scaling databases efficiently is essential to handle high-volume applications.
          </p>
          <ul className="big-bullet">
            <li><strong>Replication:</strong> Creating multiple copies of data for high availability.</li>
            <li><strong>Sharding:</strong> Distributing data across multiple databases to balance load.</li>
            <li><strong>Partitioning:</strong> Structuring large datasets for efficient queries.</li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A social media platform shards user data by region to improve query performance.
          </p>
        </section>

        {/* Consistency vs. Availability */}
        <section className="principle-section">
          <h2>2. Consistency vs. Availability</h2>
          <p>
            <strong>Explanation:</strong> The CAP theorem explains trade-offs between consistency, availability, and partition tolerance.
          </p>
          <ul className="big-bullet">
            <li><strong>Strong Consistency:</strong> Guarantees all reads return the latest write.</li>
            <li><strong>Eventual Consistency:</strong> Ensures data is eventually updated across distributed systems.</li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A banking system requires strong consistency, while a social media news feed can rely on eventual consistency.
          </p>
        </section>

        {/* Polyglot Persistence */}
        <section className="principle-section">
          <h2>3. Polyglot Persistence</h2>
          <p>
            <strong>Explanation:</strong> Using different types of databases for different use cases improves efficiency.
          </p>
          <ul className="big-bullet">
            <li><strong>SQL Databases:</strong> Best for structured data and ACID compliance.</li>
            <li><strong>NoSQL Databases:</strong> Optimized for scalability and unstructured data.</li>
            <li><strong>NewSQL Databases:</strong> Combines NoSQL scalability with SQL consistency.</li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> An e-commerce platform uses SQL for transactions and NoSQL for product recommendations.
          </p>
        </section>

        {/* Caching Strategies */}
        <section className="principle-section">
          <h2>4. Caching Strategies</h2>
          <p>
            <strong>Explanation:</strong> Caching reduces database load and speeds up responses for frequently accessed data.
          </p>
          <ul className="big-bullet">
            <li><strong>In-Memory Caching:</strong> Using Redis or Memcached for fast data retrieval.</li>
            <li><strong>Content Delivery Network (CDN):</strong> Storing static assets close to users.</li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A video streaming service caches frequently watched videos at the edge for faster delivery.
          </p>
        </section>

        {/* Data Governance & Security */}
        <section className="principle-section">
          <h2>5. Data Governance & Security</h2>
          <p>
            <strong>Explanation:</strong> Managing and protecting data is essential for compliance and security.
          </p>
          <ul className="big-bullet">
            <li><strong>Encryption:</strong> Protecting data at rest and in transit.</li>
            <li><strong>Access Control:</strong> Restricting access using authentication and authorization.</li>
            <li><strong>Compliance:</strong> Following regulations like GDPR and HIPAA.</li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A healthcare application encrypts patient data and restricts access to authorized personnel.
          </p>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>
            Effective data management ensures applications remain scalable, consistent, and secure. By leveraging database scaling, caching, polyglot persistence, and governance practices, developers can optimize data-driven applications.
          </p>
        </section>

        {/* Redirections */}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }}
          href="/lessons"
        >
          Back To Lessons
        </a>
      </div>
    </div>
  );
};

export default Lesson10;
