import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson8 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lesson 8 Page',
      label: 'Lesson 8 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 8: Scalability Patterns for Cloud-Native Applications</h1>
        <p>
          Scaling cloud applications efficiently is critical for handling growth, ensuring performance, and optimizing costs.
          This lesson explores key scalability patterns and best practices for building highly scalable cloud-native systems.
        </p>

        {/* Horizontal vs. Vertical Scaling */}
        <section className="principle-section">
          <h2>1. Horizontal vs. Vertical Scaling</h2>
          <p>
            <strong>Explanation:</strong> Choosing the right scaling approach impacts system performance and cost efficiency.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Horizontal Scaling:</strong> Adding more instances to distribute load dynamically.
            </li>
            <li>
              <strong>Vertical Scaling:</strong> Increasing resource capacity (CPU, RAM) on a single machine.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A global e-commerce platform scales horizontally by adding more application servers during sales events.
          </p>
        </section>

        {/* Auto-Scaling & Load Balancing */}
        <section className="principle-section">
          <h2>2. Auto-Scaling & Load Balancing</h2>
          <p>
            <strong>Explanation:</strong> Automatically adjusting resources based on demand ensures high availability and cost efficiency.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Auto-Scaling:</strong> Dynamically provisioning instances to match traffic fluctuations.
            </li>
            <li>
              <strong>Load Balancing:</strong> Distributing incoming requests across multiple servers for optimal utilization.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A video streaming platform automatically scales up its server pool during peak hours to handle traffic spikes.
          </p>
        </section>

        {/* Caching Strategies */}
        <section className="principle-section">
          <h2>3. Caching Strategies</h2>
          <p>
            <strong>Explanation:</strong> Using caching reduces database queries and speeds up response times for frequently accessed data.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Redis/Memcached:</strong> In-memory caching for quick data retrieval.
            </li>
            <li>
              <strong>Content Delivery Network (CDN):</strong> Caching static assets close to users.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A news website uses a CDN to serve images and articles faster to users worldwide.
          </p>
        </section>

        {/* Sharding & Partitioning */}
        <section className="principle-section">
          <h2>4. Sharding & Partitioning</h2>
          <p>
            <strong>Explanation:</strong> Dividing large datasets into smaller partitions improves database scalability and performance.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Sharding:</strong> Splitting data across multiple databases to balance load.
            </li>
            <li>
              <strong>Partitioning:</strong> Storing data based on specific attributes (e.g., date-based partitions in a log database).
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A social media platform shards user data by region to reduce query load on a single database instance.
          </p>
        </section>

        {/* Eventual Consistency & CQRS */}
        <section className="principle-section">
          <h2>5. Eventual Consistency & CQRS</h2>
          <p>
            <strong>Explanation:</strong> Ensuring scalability by separating read and write operations and accepting eventual consistency where needed.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Eventual Consistency:</strong> Allows distributed systems to synchronize data asynchronously.
            </li>
            <li>
              <strong>Command Query Responsibility Segregation (CQRS):</strong> Splitting write-heavy and read-heavy workloads.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> An online banking application uses CQRS to optimize real-time transaction processing and analytics separately.
          </p>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>
            Applying scalability patterns ensures cloud-native applications remain performant and cost-effective. By leveraging auto-scaling, caching, sharding, and eventual consistency, architects can build robust, scalable systems ready for dynamic workloads.
          </p>
        </section>

        {/* Redirections */}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }}
          href="/lessons"
        >
          Back To Lessons
        </a>
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            color: 'rgb(0, 84, 147)',
            marginLeft: '30px',
          }}
          href="/lessons/lesson9"
        >
          Next Lesson
        </a>
      </div>
    </div>
  );
};

export default Lesson8;
