import React, { useEffect } from 'react';
import '../styles/CollaboratePage.css'; // Add styling as necessary
import ReactGA from 'react-ga4';

const CollaboratePage = () => {
  useEffect(() => {
    // Log the page visit to Google Analytics
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Collaborate Page',
      label: 'Collaborate Page',
    });
  }, []);

  return (
    <div className="collaborate-container">
      <div className="collaborate-card">
        <h1>Collaborate with Me</h1>
        <p>
        I’m on a mission to help developers, technical leads, and architects enhance their understanding of software architecture and engineering. If you're hosting a <strong>Podcast, Event, or Tech Talk</strong> and want to explore topics like <strong>Software Architecture, IoT, or Cloud Computing</strong>, I’d be excited to collaborate!
        </p>
        <p>Drop me an email at <strong>collabwithbhuvnesh@gmail.com</strong></p>
        <h3>How I Can Contribute</h3>
        <p>
          With 15 years of experience as a <strong>Software Architect and Engineering Leader</strong>, I have been actively sharing my knowledge through <strong>InsightVeda.com, LinkedIn, Twitter</strong>, and other platforms. My focus is on <strong>helping engineers design scalable systems, solve complex problems, and drive innovation</strong> by bridging the gap between theory and real-world application.
        </p>
        <ul>
          <li><strong>Unpack key architectural concepts</strong> in an engaging and practical way.</li>
          <li><strong>Share real-world insights</strong> on building scalable and resilient systems.</li>
          <li><strong>Guide engineers and leaders</strong> in making informed technical decisions.</li>
        </ul>
        <p>
          Let’s create meaningful and impactful discussions together! Reach out and let’s talk.
        </p>
      </div>
    </div>
  );
};

export default CollaboratePage;
