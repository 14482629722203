import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson7 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lesson 7 Page',
      label: 'Lesson 7 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 7: Cloud and Software Architecture for IoT Systems</h1>
        <p>
          Designing IoT architectures requires a deep understanding of cloud computing, real-time data processing, and secure device communication. 
          This lesson explores key concepts and best practices for building scalable IoT solutions.
        </p>

        {/* Scalable IoT Architecture */}
        <section className="principle-section">
          <h2>1. Scalable IoT Architecture</h2>
          <p>
            <strong>Explanation:</strong> IoT systems must handle massive device connectivity, high-frequency data streams, and dynamic workloads.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Edge Processing:</strong> Processing data near the source to reduce latency.
            </li>
            <li>
              <strong>Cloud Storage:</strong> Leveraging cloud databases for scalable data persistence.
            </li>
            <li>
              <strong>Event-Driven Architecture:</strong> Using pub-sub messaging for real-time event processing.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A smart home system where edge devices process basic automation locally, while cloud services analyze aggregated sensor data for long-term insights.
          </p>
        </section>

        {/* Edge vs. Cloud Computing */}
        <section className="principle-section">
          <h2>2. Edge vs. Cloud Computing</h2>
          <p>
            <strong>Explanation:</strong> Balancing computational tasks between edge devices and cloud platforms is crucial for performance and cost efficiency.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Edge Computing:</strong> Reduces network congestion by processing data closer to IoT devices.
            </li>
            <li>
              <strong>Cloud Computing:</strong> Provides scalability and long-term data storage.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A connected vehicle system processes braking decisions at the edge while sending driving patterns to the cloud for predictive maintenance.
          </p>
        </section>

        {/* IoT Messaging & Event Processing */}
        <section className="principle-section">
          <h2>3. IoT Messaging & Event Processing</h2>
          <p>
            <strong>Explanation:</strong> Reliable messaging ensures efficient data exchange between IoT devices and cloud applications.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>MQTT:</strong> A lightweight messaging protocol optimized for low-bandwidth networks.
            </li>
            <li>
              <strong>Apache Kafka:</strong> A distributed event streaming platform for processing large-scale IoT data.
            </li>
            <li>
              <strong>Serverless Functions:</strong> Triggering lightweight cloud functions in response to device events.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A fleet management system using MQTT for real-time GPS tracking and Kafka for large-scale analytics on vehicle movements.
          </p>
        </section>

        {/* Security & Reliability */}
        <section className="principle-section">
          <h2>4. Security & Reliability in IoT</h2>
          <p>
            <strong>Explanation:</strong> Ensuring secure communication and system reliability is essential for IoT deployments.
          </p>
          <ul className="big-bullet">
            <li>
              <strong>Data Encryption:</strong> Encrypting data in transit and at rest to protect sensitive information.
            </li>
            <li>
              <strong>Device Authentication:</strong> Using certificates and tokens to validate device identities.
            </li>
            <li>
              <strong>High Availability:</strong> Deploying redundant cloud regions and failover mechanisms.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A healthcare IoT system ensuring secure and reliable transmission of patient vitals from wearable devices to cloud-based health dashboards.
          </p>
        </section>

        {/* 5. Alerts and Notifications */}
        <section className="principle-section">
          <h2>5. Alerts and Notifications</h2>
          <p>
            <strong>Explanation:</strong> Real-time alerts and notifications ensure users are informed of critical IoT events instantly.
          </p>
          <ul className="big-bullet">
            <li><strong>Push Notifications:</strong> Mobile or email alerts for emergency events.</li>
            <li><strong>Automated Responses:</strong> Trigger corrective actions based on sensor data.
            </li>
          </ul>
          <p>
            <strong>Example Use Case:</strong> A smart factory sends instant alerts to engineers when machine sensors detect overheating.
          </p>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>
            Cloud and software architecture for IoT systems must be designed with scalability, low latency, security, and fault tolerance in mind. 
            By leveraging edge computing, cloud platforms, event-driven messaging, and security best practices, developers can build robust and efficient IoT solutions.
          </p>
        </section>

        {/* Redirections */}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }}
          href="/lessons"
        >
          Back To Lessons
        </a>
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '1rem',
            color: 'rgb(0, 84, 147)',
            marginLeft: '30px',
          }}
          href="/lessons/lesson8"
        >
          Next Lesson
        </a>
      </div>
    </div>
  );
};

export default Lesson7;
