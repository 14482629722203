// Header.js

import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import theme from '../styles/theme';
import ReactGA from 'react-ga4';
import '../styles/Header.css';
import { UserContext } from '../contexts/UserContext';

// Separate UserProfile component wrapped with React.memo
const UserProfile = React.memo(({ user, isUserAdmin, handleSignOut }) => (
  <div className="user-profile-dropdown">
    <span className="user-profile-text">{user.firstName[0]}{user.lastName[0]}</span>
    <img src="/images/avatar-icon.png" alt="Menu Button" className="menu-icon" />
    <div className="dropdown-content">
      <NavItem href="/my-account">My Account</NavItem>
      {isUserAdmin && <NavItem href="/manage-users">Manage Users</NavItem>}
      {isUserAdmin && <NavItem href="/send-lesson-email">Send Lesson Email</NavItem>}
      <NavItem onClick={handleSignOut}>Sign Out</NavItem>
    </div>
  </div>
));

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoggedIn, setIsLoggedIn, user, setUser, isUserAdmin  } = useContext(UserContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Header Section',
      label: 'Header Section',
    });
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const handleSignOut = useCallback(() => {
    // Clear application-specific session state
    setIsLoggedIn(false);
    setUser(null);
  
    // Remove authentication data from local storage
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('authChecked');
    localStorage.removeItem('authHandled');
    
    // Clear OAuth session cookies (if applicable)
    document.cookie = "g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "oauth_refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    navigate('/sign-in');
  }, [navigate, setIsLoggedIn, setUser]);   
  

  return (
    <HeaderContainer>
      <Logo href="/">
        <img src="/images/insight-veda-logo-transparent.png" alt="Insight Veda Logo" />
      </Logo>

      <div className="menu-toggle" onClick={toggleMenu}>
        <span className="menu-text">Menu</span>
        <img src="/images/menu-icon.png" alt="Menu Button" className="menu-icon" />
      </div>

      <div className={`nav-container ${isMenuOpen ? 'open' : ''}`}>
        <Nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          <NavItem href="/">Home</NavItem>
          <NavItem href="/lessons">Lessons</NavItem>
          <NavItem href="/challenges">Challenges</NavItem>
          <NavItem href="/resources">Resources</NavItem>
          <NavItem href="/bhuvnesh-arya">About</NavItem>
          <NavItem href="/collaborate">Collaborate</NavItem>
          {isLoggedIn ? (
            <UserProfile user={user} isUserAdmin={isUserAdmin} handleSignOut={handleSignOut} />
          ) : (
            <NavItem href="/sign-in">Sign In</NavItem>
          )}
        </Nav>
      </div>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: ${theme.colors.primaryBackground};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.a`
  img {
    width: 140px;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
`;

const NavItem = styled.a`
  color: ${theme.colors.text};
  text-decoration: none;
  &:hover {
    color: ${theme.colors.accent};
  }
`;


export default Header;
