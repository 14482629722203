import React, { useEffect } from 'react';
import '../styles/AboutMe.css'; // Make sure to include your CSS file
import { trackOutboundLinkClick } from '../components/analytics';
import ReactGA from 'react-ga4';

const AboutMe = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit AboutMe Page',
      label: 'AboutMe Page',
    });
  }, []);
  return (
    <div className="about-me-section">
      <div className="about-me-content">
      <div className="about-me-image">
        <img src="/images/bhuvnesh-arya.avif" alt="About Me" />
      </div>
        <h1>Bhuvnesh Arya</h1>
        <p1><b>Architect. Technologist. Mentor.</b></p1>
        <span></span>
        <p>
        I am a <b>Software Architect and Engineering Leader</b> with 15 years of experience in designing scalable, high-performance systems. My passion lies in <b>Software Architecture, IoT, and Cloud Computing</b>, helping developers and technical leaders build robust and future-ready solutions.
        </p>
        <p>
        As a <b>Software Architect at Wesco</b> and former <b>Lead Developer at Siemens</b>, I have worked on complex, mission-critical architectures that power modern enterprises. Beyond my professional roles, I founded InsightVeda.com to empower developers with <b>expert lessons, real-world challenges, and curated resources</b>—equipping them with the skills to design scalable systems, solve complex problems, and drive innovation.
        </p>
        <p>
        I am deeply committed to <b>mentoring and knowledge-sharing</b>, having guided <b>hundreds of professionals and college students</b> in mastering system design, architecture, and career growth. Through <b>Insight Veda</b>, I strive to bridge the gap between theory and real-world application, ensuring that developers, technical leads, and architects can make informed decisions, build resilient architectures, and advance their software journey.
        </p>
        <p>I dive deep into software architecture and share my learnings across my socials.</p>
        <p>Follow me on:</p>
        
        <div class="social-links-aboutme">
          <a href="https://www.linkedin.com/in/bhuvnesh-arya/" target="_blank" class="social-link linkedin" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.linkedin.com/in/bhuvnesh-arya/')}>
            <img src="/images/linkedin.png" alt="LinkedIn" />
          </a>
          <a href="https://x.com/Bhuvneshdot/" target="_blank" class="social-link twitter" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://x.com/Bhuvneshdot/')}>
            <img src="/images/twitter-x.png" alt="Twitter" />
          </a>
          <a href="https://www.youtube.com/@bhuvnesharya10/" target="_blank" class="social-link youtube" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.youtube.com/@bhuvnesharya10/')}>
            <img src="/images/youtube.png" alt="Youtube" />
          </a>
          <a href="https://github.com/bhuvnesharya/" target="_blank" class="social-link github" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://github.com/bhuvnesharya/')}>
            <img src="/images/github.png" alt="GitHub" />
          </a>
        </div>

      </div>
      
    </div>
  );
};

export default AboutMe;
