import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/ChallengesPage.css';

const Challenge6 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Challenge 6 Page',
      label: 'Challenge 6 Page',
    });
  }, []);

  return (
    <div className="challenges-container">
      <div className="challenges-content">
        <h1>Challenge 6: Designing a Multi-Tenant SaaS Architecture</h1>

        {/* Overview */}
        <section className="principle-section">
          <h2>Overview</h2>
          <p>
            Multi-tenant SaaS applications serve multiple customers (tenants) using a shared infrastructure while ensuring data isolation and scalability.
          </p>
        </section>

        {/* Objective */}
        <section className="principle-section">
          <h2>Objective</h2>
          <p>
            Design a scalable, secure multi-tenant SaaS architecture that allows multiple organizations to use the same platform efficiently.
          </p>
        </section>

        {/* Requirements */}
        <section className="principle-section">
          <h2>Requirements</h2>
          <ul className="big-bullet">
            <li>
              <strong>Tenant Management:</strong>
              <ul className="small-bullet">
                <li>Support multiple organizations with role-based access control.</li>
                <li>Implement tenant provisioning and lifecycle management.</li>
              </ul>
            </li>
            <li>
              <strong>Data Isolation:</strong>
              <ul className="small-bullet">
                <li>Choose between database per tenant, schema per tenant, or shared database models.</li>
                <li>Ensure tenant-level access control and authorization.</li>
              </ul>
            </li>
            <li>
              <strong>Scalability & Performance:</strong>
              <ul className="small-bullet">
                <li>Optimize resource allocation for different tenants.</li>
                <li>Implement caching and load balancing strategies.</li>
              </ul>
            </li>
            <li>
              <strong>Security & Compliance:</strong>
              <ul className="small-bullet">
                <li>Encrypt tenant data in transit and at rest.</li>
                <li>Ensure compliance with GDPR, SOC 2, and other relevant standards.</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* Steps to Solve */}
        <section className="principle-section">
          <h2>Steps to Solve</h2>
          <ul>
            <li>Choose a multi-tenancy model based on business needs.</li>
            <li>Design the database schema to handle multiple tenants securely.</li>
            <li>Implement authentication and authorization mechanisms for tenants.</li>
            <li>Optimize the architecture for scalability and performance.</li>
            <li>Ensure logging, monitoring, and security best practices.</li>
          </ul>
        </section>

        {/* Hints */}
        <section className="principle-section">
          <h2>Hints</h2>
          <ul>
            <li>Explore Azure, AWS, or GCP services for multi-tenancy.</li>
            <li>Use service meshes or API gateways to manage tenant requests.</li>
            <li>Consider using database sharding for better scalability.</li>
          </ul>
        </section>

        {/* Example Use Case */}
        <section className="principle-section">
          <h2>Example Use Case</h2>
          <p>
            Imagine a SaaS platform that provides HR management solutions to different companies:
            <ul>
              <li>Each company (tenant) has its own users and roles.</li>
              <li>Data for each tenant is stored securely with proper isolation.</li>
              <li>The platform scales efficiently as new companies join.</li>
            </ul>
          </p>
        </section>

        {/* Bonus Points */}
        <section className="principle-section">
          <h2>Bonus Points</h2>
          <ul>
            <li>Implement per-tenant customization options.</li>
            <li>Use Kubernetes for dynamic scaling.</li>
            <li>Set up automated tenant provisioning workflows.</li>
          </ul>
        </section>

        {/* Resources */}
        <section className="principle-section">
          <h2>Resources</h2>
          <ul>
            <li>
              <a href="https://learn.microsoft.com/en-us/azure/architecture/example-scenario/multi-saas/multitenant-saas" target="_blank" rel="noopener noreferrer">
                Microsoft Azure Multi-Tenant SaaS Architecture
              </a>
            </li>
            <li>Other resources relevant to your tech stack.</li>
          </ul>
        </section>

        {/* Navigation Links */}
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/challenges">Back To Challenges</a>
      </div>
    </div>
  );
};

export default Challenge6;
