import React from 'react';
import '../styles/ResourcesPage.css';

const resources = [
    
    {
        category: 'Software Architecture',
        links: [
            { title: 'Microsoft Azure Architecture Center', url: 'https://learn.microsoft.com/en-us/azure/architecture/' },
            { title: 'Martin Fowler’s Software Architecture Articles', url: 'https://martinfowler.com/architecture/' },
            { title: 'ThoughtWorks Tech Radar', url: 'https://www.thoughtworks.com/radar' },
            { title: 'Microservices Architecture', url: 'https://microservices.io/' },
            { title: 'Event-Driven Architecture (EDA) by Martin Fowler', url: 'https://martinfowler.com/articles/201701-event-driven.html' },
            { title: 'Domain-Driven Design Reference by Eric Evans', url: 'https://domainlanguage.com/ddd/reference/' },
            { title: 'AWS Well-Architected Framework', url: 'https://aws.amazon.com/architecture/well-architected/' },
            { title: 'Google Cloud Architecture Framework', url: 'https://cloud.google.com/architecture/framework' },
            { title: 'InfoQ Software Architecture & Design', url: 'https://www.infoq.com/architecture-design/' },
            { title: 'Microsoft’s Cloud Design Patterns', url: 'https://learn.microsoft.com/en-us/azure/architecture/patterns/' },
        ]
    },

    {
        category: 'System Design',
        links: [
            { title: 'Designing Data-Intensive Applications', url: 'https://dataintensive.net' },
            { title: 'The Twelve-Factor App', url: 'https://12factor.net' },
            { title: 'High Scalability', url: 'http://highscalability.com/' },
            { title: 'System Design Primer (GitHub)', url: 'https://github.com/donnemartin/system-design-primer' },
            { title: 'Netflix Tech Blog - Scaling & Architecture', url: 'https://netflixtechblog.com/' },
            { title: 'Principles of Distributed Computing', url: 'https://www.distributed-systems.net/' },
            { title: 'System Design for Beginners - Alex Xu', url: 'https://bytebytego.com/' },
            { title: 'Uber Engineering Blog - Distributed Systems and Scalability', url: 'https://eng.uber.com/category/architecture/' },
            { title: 'Dropbox Tech Blog - Scaling Systems', url: 'https://dropbox.tech/infrastructure' },
            { title: 'Google Site Reliability Engineering', url: 'https://sre.google/sre-book/table-of-contents/' }
        ]
    },

    {
        category: 'Backend System Design & Real-World Architectures',
        links: [
            { title: 'Scaling Memcache at Facebook', url: 'https://research.facebook.com/publications/scaling-memcache-at-facebook/' },
            { title: 'Kafka: A Distributed Messaging System', url: 'https://kafka.apache.org/documentation/' },
            { title: 'Uber’s Optimized Cassandra Operations at Scale', url: 'https://www.uber.com/blog/how-uber-optimized-cassandra-operations-at-scale/' },
            { title: 'Inside Dropbox’s Magic Pocket for Exabyte-Scale Storage', url: 'https://dropbox.tech/infrastructure/inside-the-magic-pocket' },
            { title: 'Designing WhatsApp for Scalability', url: 'https://highscalability.com/designing-whatsapp/' },
            { title: 'Twitter’s Manhattan Event Storage System', url: 'https://blog.x.com/engineering/en_us/a/2014/manhattan-our-real-time-multi-tenant-distributed-database-for-twitter-scale' },
            { title: 'Shopify’s Sharding and Scaling Strategy with MySQL', url: 'https://shopify.engineering/mysql-database-shard-balancing-terabyte-scale' },
            { title: 'A Brief History of Scaling LinkedIn’s Service Architecture', url: 'https://engineering.linkedin.com/architecture/brief-history-scaling-linkedin' },
            { title: 'Netflix’s Real-Time Data Processing Platform Keystone', url: 'https://netflixtechblog.com/keystone-real-time-stream-processing-platform-a3ee651812a' },
            { title: 'How PayPal Achieves High Availability', url: 'https://blog.bytebytego.com/p/how-paypal-serves-350-billion-daily' }
        ]
    },

    {
        category: 'Database Systems & Best Practices',
        links: [
            { title: 'Introduction to Relational Databases (PostgreSQL)', url: 'https://www.postgresql.org/docs/' },
            { title: 'MongoDB Architecture and Data Modeling', url: 'https://www.mongodb.com/docs/' },
            { title: 'Database Internals: Foundations of SQL Databases', url: 'https://sqlfordevs.com/' },
            { title: 'Designing Scalable Databases with Cassandra', url: 'https://cassandra.apache.org/_/index.html' },
            { title: 'Redis in Action: Real-Time Data Processing', url: 'https://redis.io/documentation' },
            { title: 'Event Sourcing and CQRS with NoSQL', url: 'https://martinfowler.com/eaaDev/EventSourcing.html' },
            { title: 'Choosing Between SQL and NoSQL Databases', url: 'https://aws.amazon.com/nosql/' },
            { title: 'CockroachDB: Distributed SQL at Scale', url: 'https://www.cockroachlabs.com/docs/' },
            { title: 'Elasticsearch for Full-Text Search and Analytics', url: 'https://www.elastic.co/guide/' },
            { title: 'ArangoDB: Multi-Model Database Architecture', url: 'https://www.arangodb.com/docs/' },
        ]
    },

    {
        category: 'Development Roadmaps',
        links: [
            { title: 'Perfect Roadmap to Learn Java & Spring Boot in 2024', url: 'https://github.com/bhuvnesharya/Perfect-Roadmap-To-Learn-Java-SpringBoot-In-2024' },
            { title: 'Frontend Development Roadmap – JS & React in 2024', url: 'https://github.com/bhuvnesharya/Frontend-Development-Roadmap-JS-React-In-2024' },
        ]
    }
    
    
  // Add more categories as needed
];

const ResourcesPage = () => {
  return (
    <div className="resources-page-container">
        <section className="resources-content">
            <h1>Resources</h1>
            <p>Explore a <b style={{ fontStyle: 'italic' }}>curated selection of top-tier resources, </b> 
            including in-depth articles, research papers, and guides on software architecture, system design, big data, development roadmaps and more. </p>
                {resources.map((section, index) => (
                    <div key={index} className="resource-section">
                    <h3>{section.category}</h3>
                    <ul>
                        {section.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.title}</a>
                        </li>
                        ))}
                    </ul>
                    </div>
                ))}
        </section>
    </div>
  );
};

export default ResourcesPage;
