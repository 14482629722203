import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { trackOutboundLinkClick } from './analytics';
import '../styles/Footer.css'; 
import ReactGA from 'react-ga4';

const Footer = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Footer Section',
      label: 'Footer Section',
    });
  }, []);
  return (
    <FooterContainer>
      <LeftSection>
        <LogoSocials>
          <a href="/" className="logo">
            <img src="/images/insight-veda-white-logo-transparent.png" alt="Logo" className="logo-footer-image" />
          </a>
        </LogoSocials>
        <SubscribeSection>
          <span><span style={{color: "#FFBB00"}}>Bhuvnesh's Newsletter</span> read by 500+ software professionals.</span>
          <p>Weekly insights on software architecture lessons, challenges, key takeaways, and interesting reads.</p>
          <SubscribeButtons>
            <a className="libutton" href="https://www.linkedin.com/newsletters/7293898987454050304/" target="_blank" rel="noopener noreferrer">Subscribe on LinkedIn</a>
            <a className="substack-button" href="https://bhuvnesharya.substack.com/" target="_blank" rel="noopener noreferrer">Subscribe on Substack</a>
          </SubscribeButtons>
          <div class="social-links-aboutme">
            <a style={{backgroundColor: "#303846"}} href="https://www.linkedin.com/in/bhuvnesh-arya/" target="_blank" class="social-link linkedin" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.linkedin.com/in/bhuvnesh-arya/')}>
              <img src="/images/linkedin-white.png" alt="LinkedIn" />
            </a>
            <a style={{backgroundColor: "#303846"}} href="https://x.com/Bhuvneshdot/" target="_blank" class="social-link twitter" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://x.com/Bhuvneshdot/')}>
              <img src="/images/twitter-white.png" alt="LinkedIn" />
            </a>
            <a style={{backgroundColor: "#303846"}} href="https://www.youtube.com/@bhuvnesharya10/" target="_blank" class="social-link youtube" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.youtube.com/@bhuvnesharya10/')}>
              <img src="/images/youtube-white.png" alt="Youtube" />
            </a>
            <a style={{backgroundColor: "#303846"}} href="https://github.com/bhuvnesharya/" target="_blank" class="social-link github" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://github.com/bhuvnesharya/')}>
              <img src="/images/github-white.png" alt="GitHub" />
            </a>
          </div>
        </SubscribeSection>
      </LeftSection>
      <RightSections>
        <Community>
          <p>Community</p>
          <a href="https://www.linkedin.com/company/insightveda/" target="_blank" className="social-link linkedin" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.linkedin.com/company/insightveda/')}>
              LinkedIn
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <g id="Interface / External_Link">
                    <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </g>
              </svg>
          </a>
        </Community>
        <WritingsLearnings>
          <p>Learnings and Challenges</p>
          <a href="/lessons">Lessons</a>
          <a href="/challenges">Challenges</a>
          <a href="/resources">Resources</a>
        </WritingsLearnings>
        <LegalContact>
          <p>More</p>
          <a href="/bhuvnesh-arya">About Bhuvnesh</a>
          <a href="/collaborate">Collaborate with Me</a>
          <a href="/privacy-policy.html">Privacy Policy</a>
        </LegalContact>
      </RightSections>
      <FooterText>&copy; 2024-2025 Bhuvnesh Arya</FooterText>
    </FooterContainer> 
  );
};

const FooterContainer = styled.footer`
  background-color: #303846;
  color: ${theme.colors.whiteText};
  padding: 40px 20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const FooterText = styled.p`
  width: 100%;
  text-align: left;
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.typography.bodySmall.fontSize};
  font-weight: ${theme.typography.bodySmall.fontWeight};
  line-height: ${theme.typography.bodySmall.lineHeight};
  margin-top: 20px;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const RightSections = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 2;
`;

const LogoSocials = styled.div`
  .logo-image {
    max-width: 150px;
  }
`;

const SubscribeSection = styled.div`
  margin-top: 10px;
  p {
    font-size: 1rem;
    text-align: left;
  }
  a {
    background: #FFBB00;
    padding: 10px 20px;
    border-radius: 5px;
    color: #000;
    font-weight: bold;
    text-decoration: none;
  }
`;

const Community = styled.div`
  flex: 1;
  p {
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
  }
  a {
    display: block;
    font-size: 1rem;
    color: ${theme.colors.whiteText};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: #FFBB00;
    }
    
    svg {
      width: 15px; /* Size of icons */
      height: 15px;
      transition: transform 0.3s ease; /* Smooth animation on hover */
      margin-left: 2px;
      text-align: left;
    }

  }
`;

const WritingsLearnings = styled.div`
  text-align: center;
  flex: 1;
  p {
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
  }
  a {
    display: block;
    font-size: 1rem;
    color: ${theme.colors.whiteText};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: #FFBB00;
    }
  }
`;

const LegalContact = styled.div`
  text-align: center;
  flex: 1;
  p {
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
  }
  a {
    display: block;
    font-size: 1rem;
    color: ${theme.colors.whiteText};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: #FFBB00;
    }
  }
`;

const SubscribeButtons = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  .libutton, .substack-button {
    background-color: #0A66C2;
    color: white;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.75rem;
    width: 150px;
    white-space: nowrap;
  }
  .substack-button {
    color: #000000;
    background-color: #FFBB00;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    .libutton, .substack-button {
      width: 90%;
    }
  }
`;

export default Footer;
