import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/ChallengesPage.css';

const Challenge3 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Challenge 3 Page',
      label: 'Challenge 3 Page',
    });
  }, []);

  return (
    <div className="challenges-container">
      <div className="challenges-content">
        <h1>Challenge 3: Designing an Event-Driven Architecture</h1>

        {/* Overview */}
        <section className="principle-section">
          <h2>Overview</h2>
          <p>
            Modern distributed systems often require asynchronous communication for scalability and resilience. Event-driven architecture (EDA) enables loosely coupled components to communicate efficiently using events rather than direct API calls.
          </p>
        </section>

        {/* Objective */}
        <section className="principle-section">
          <h2>Objective</h2>
          <p>
            Design an event-driven system where microservices communicate asynchronously using an event broker (e.g., Kafka, RabbitMQ, or AWS SNS/SQS).
          </p>
        </section>

        {/* Requirements */}
        <section className="principle-section">
          <h2>Requirements</h2>
          <ul className="big-bullet">
            <li>
              <strong>Services & Events:</strong>
              <ul className="small-bullet">
                <li>Order Service: Publishes an <span className="highlighted-text">OrderPlaced</span> event when a new order is created.</li>
                <li>Inventory Service: Listens to <span className="highlighted-text">OrderPlaced</span> and updates stock levels.</li>
                <li>Notification Service: Listens to <span className="highlighted-text">OrderPlaced</span> and sends confirmation emails.</li>
              </ul>
            </li>
            <li>
              <strong>Event Broker:</strong>
              <ul className="small-bullet">
                <li>Use Kafka, RabbitMQ, or any event broker to manage event flow.</li>
              </ul>
            </li>
            <li>
              <strong>Event Processing:</strong>
              <ul className="small-bullet">
                <li>Implement event consumers to process incoming events asynchronously.</li>
                <li>Ensure event-driven workflows maintain eventual consistency.</li>
              </ul>
            </li>
            <li>
              <strong>Error Handling & Resilience:</strong>
              <ul className="small-bullet">
                <li>Implement retry mechanisms for failed event processing.</li>
                <li>Use dead-letter queues (DLQs) for handling unprocessable messages.</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* Steps to Solve */}
        <section className="principle-section">
          <h2>Steps to Solve</h2>
          <ul>
            <li>Set up Order, Inventory, and Notification services.</li>
            <li>Configure an event broker to handle message flow.</li>
            <li>Publish events when actions occur in the system.</li>
            <li>Ensure consumers process events correctly.</li>
            <li>Implement failure handling and retries.</li>
          </ul>
        </section>

        {/* Hints */}
        <section className="principle-section">
          <h2>Hints</h2>
          <ul>
            <li>Understand how event-driven systems work with pub/sub models.</li>
            <li>Use event logs to track system behavior.</li>
            <li>Ensure idempotency in event consumers to avoid duplicate processing.</li>
          </ul>
        </section>

        {/* Example Use Case */}
        <section className="principle-section">
          <h2>Example Use Case</h2>
          <p>
            Imagine an e-commerce platform where:
            <ul>
              <li>The Order Service processes new orders and publishes an event.</li>
              <li>The Inventory Service listens and updates stock accordingly.</li>
              <li>The Notification Service sends an email confirmation to the user.</li>
            </ul>
            <strong>Flow:</strong>
            <ul>
              <li>User places an order via <span className="highlighted-text">/order</span>.</li>
              <li>Order Service publishes an <span className="highlighted-text">OrderPlaced</span> event.</li>
              <li>Consumers process the event asynchronously.</li>
            </ul>
          </p>
        </section>

        {/* Bonus Points */}
        <section className="principle-section">
          <h2>Bonus Points</h2>
          <ul>
            <li>Implement event versioning for backward compatibility.</li>
            <li>Use an event store to persist events for auditing and replay.</li>
            <li>Ensure message ordering in distributed environments.</li>
          </ul>
        </section>

        {/* Resources */}
        <section className="principle-section">
          <h2>Resources</h2>
          <ul>
            <li><a href="https://kafka.apache.org/documentation/" target="_blank" rel="noopener noreferrer">Apache Kafka Documentation</a></li>
            <li><a href="https://www.rabbitmq.com/getstarted.html" target="_blank" rel="noopener noreferrer">RabbitMQ Getting Started</a></li>
            <li><a href="https://aws.amazon.com/sqs/" target="_blank" rel="noopener noreferrer">AWS SQS Official Page</a></li>
            <li>Other resources relevant to your tech stack.</li>
          </ul>
        </section>

        {/* Navigation Links */}
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/challenges">Back To Challenges</a>
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)', marginLeft: '30px' }} href="/challenges/challenge4">Next Challenge</a>
        
      </div>
    </div>
  );
};

export default Challenge3;
