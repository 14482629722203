import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/ChallengesPage.css';

const Challenge4 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Challenge 4 Page',
      label: 'Challenge 4 Page',
    });
  }, []);

  return (
    <div className="challenges-container">
      <div className="challenges-content">
        <h1>Challenge 4: Implementing API Gateway for Microservices</h1>

        {/* Overview */}
        <section className="principle-section">
          <h2>Overview</h2>
          <p>
            In a microservices architecture, managing multiple services efficiently can be challenging. An API Gateway acts as a single entry point, handling authentication, rate limiting, request routing, and security.
          </p>
        </section>

        {/* Objective */}
        <section className="principle-section">
          <h2>Objective</h2>
          <p>
            Implement an API Gateway to centralize access to microservices, improving security, performance, and scalability.
          </p>
        </section>

        {/* Requirements */}
        <section className="principle-section">
          <h2>Requirements</h2>
          <ul className="big-bullet">
            <li>
              <strong>Services:</strong>
              <ul className="small-bullet">
                <li>Order Service: Handles order placement.</li>
                <li>User Service: Manages user authentication and profiles.</li>
                <li>Payment Service: Processes transactions.</li>
              </ul>
            </li>
            <li>
              <strong>API Gateway Features:</strong>
              <ul className="small-bullet">
                <li>Request routing to appropriate microservices.</li>
                <li>Authentication and authorization.</li>
                <li>Rate limiting to prevent abuse.</li>
                <li>Logging and monitoring capabilities.</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* Steps to Solve */}
        <section className="principle-section">
          <h2>Steps to Solve</h2>
          <ul>
            <li>Set up the API Gateway component.</li>
            <li>Configure route mapping to backend services.</li>
            <li>Implement authentication and rate limiting.</li>
            <li>Test request handling through the API Gateway.</li>
          </ul>
        </section>

        {/* Hints */}
        <section className="principle-section">
          <h2>Hints</h2>
          <ul>
            <li>Use tools like Kong, Traefik, or Nginx as API Gateways.</li>
            <li>Implement JWT-based authentication for security.</li>
            <li>Monitor request patterns to optimize routing.</li>
          </ul>
        </section>

        {/* Example Use Case */}
        <section className="principle-section">
          <h2>Example Use Case</h2>
          <p>
            Imagine an e-commerce platform where:
            <ul>
              <li>The Order Service, User Service, and Payment Service exist as independent microservices.</li>
              <li>Clients interact with a single API Gateway instead of calling services directly.</li>
              <li>The API Gateway handles authentication and routes requests efficiently.</li>
            </ul>
          </p>
        </section>

        {/* Bonus Points */}
        <section className="principle-section">
          <h2>Bonus Points</h2>
          <ul>
            <li>Implement caching for frequently requested data.</li>
            <li>Use WebSockets for real-time API Gateway communication.</li>
            <li>Enable logging and analytics to track API performance.</li>
          </ul>
        </section>

        {/* Resources */}
        <section className="principle-section">
          <h2>Resources</h2>
          <ul>
            <li><a href="https://konghq.com/" target="_blank" rel="noopener noreferrer">Kong API Gateway</a></li>
            <li><a href="https://traefik.io/" target="_blank" rel="noopener noreferrer">Traefik API Gateway</a></li>
            <li><a href="https://nginx.org/en/docs/http/ngx_http_proxy_module.html" target="_blank" rel="noopener noreferrer">Nginx as API Gateway</a></li>
            <li>Other resources relevant to your tech stack.</li>
          </ul>
        </section>

        {/* Navigation Links */}
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/challenges">Back To Challenges</a>
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)', marginLeft: '30px' }} href="/challenges/challenge5">Next Challenge</a>
        
      </div>
    </div>
  );
};

export default Challenge4;
